import { useMemo } from 'react';
import { DTDataWithState } from '../store/slice.helpers';

export const useDTListCombinedData = <T>(dtList: DTDataWithState<T>[]) => {
  const combinedData = useMemo(() => {
    return dtList.reduce((acc, { data }) => {
      return [...acc, ...(data || [])];
    }, []);
  }, [dtList]);

  return combinedData;
};
