import { useMemo } from 'react';
import { DTDataWithState } from '../store/slice.helpers';

export const useDTListCombinedError = <T>(dtList: DTDataWithState<T>[]) => {
  const isBlockFailed = useMemo(() => {
    return dtList.some(({ error }) => !!error);
  }, [dtList]);

  return isBlockFailed;
};
