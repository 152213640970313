import { Key } from '../../../store/feed/feed.types';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { keyDataSelector } from '../../../store/feed/feed.selectors';
import { useDTListCombinedData } from '../../../hooks/useDTListCombinedData';
import { useDTListCombinedHasMore } from '../../../hooks/useDTListCombinedHasMore';
import { useDTListCombinedLoading } from '../../../hooks/useDTListCombinedLoading';
import Flickity, { FlickityOptions } from 'react-flickity-component';
import { getIsRtl } from '../../../locale/i18n';
import { getFeed } from '../../../store/feed/feed.slice';
import { FeedBlockItemSkeleton, FeedBlockSkeleton } from './Skeleton';
import { FEED_BLOCK_CONFIGS } from '../../../store/feed/feed.constants';
import FeedResultItem from './FeedResultItem';
import styled from 'styled-components';
import { authSelector } from '../../../store/auth/auth.selectors';
import { useDTListCombinedError } from '../../../hooks/useDTListCombinedError';
import InfoBlock from './InfoBlock';

interface IFeedBlockProps {
  type: Key;
  isBig?: boolean;
}

const FeedBlock: React.FC<IFeedBlockProps> = ({ type, isBig }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [currentIndex, setCurrentIndex] = useState(0);
  const flickityRef = useRef(null);
  const feedBlockData = useSelector(keyDataSelector(type));
  const { first_name: firstName } = useSelector(authSelector);
  const data = useDTListCombinedData(feedBlockData);
  const hasMore = useDTListCombinedHasMore(feedBlockData);
  const isLoading = useDTListCombinedLoading(feedBlockData);
  const isInitialLoading = feedBlockData[0].isLoading && feedBlockData[0].data === null;
  const isError = useDTListCombinedError(feedBlockData);

  const isRtl = getIsRtl();

  const listOptions: FlickityOptions = useMemo(
    () => ({
      prevNextButtons: false,
      pageDots: false,
      cellAlign: isRtl ? 'right' : 'left',
      rightToLeft: isRtl,
      initialIndex: currentIndex,
    }),
    [currentIndex, isRtl],
  );

  const onChangeCurrentIndex = useCallback(
    (index: number) => {
      if (index >= data?.length - 6 && hasMore && !isLoading && !isError) {
        dispatch(getFeed({ key: type }));
      }

      if (index >= data?.length - 2 && data?.length - 2 >= 0 && !hasMore && !isLoading) {
        setCurrentIndex(data?.length - 2);
        flickityRef.current.select(data?.length - 2);
        return;
      }

      setCurrentIndex(index);
      flickityRef.current.select(index);
    },
    [data?.length, hasMore, isLoading, isError, dispatch, type],
  );

  useEffect(() => {
    const handler = (index: number) => {
      onChangeCurrentIndex(index);
    };

    flickityRef.current?.on('change', handler);

    return () => {
      flickityRef.current?.off('change.flickity', handler);
    };
  }, [onChangeCurrentIndex]);

  useEffect(() => {
    if (!data.length && !isInitialLoading && !isError) {
      dispatch(getFeed({ key: type }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isInitialLoading, type, isError]);

  const setFlickityRef = useCallback((c) => {
    flickityRef.current = c;
  }, []);

  const handleSeeAll = useCallback(() => {
    history.push(`/feed/${type}`);
  }, [history, type]);

  if (!isInitialLoading && !data?.length && !isError && type !== 'todo') {
    return null;
  }

  return (
    <S.FeedBlock>
      {isInitialLoading ? (
        <FeedBlockSkeleton size={isBig ? 'big' : 'small'} />
      ) : data?.length || isError ? (
        <>
          <S.FeedBlockHeader isRtl={isRtl}>
            <S.FeedBlockTitle>{t(FEED_BLOCK_CONFIGS[type].title, { firstName })}</S.FeedBlockTitle>
            <S.SeeAllButton onClick={handleSeeAll}>{t('feed.blockTitles.seeAll')}</S.SeeAllButton>
          </S.FeedBlockHeader>
          <S.Flickity elementType='div' options={listOptions} flickityRef={setFlickityRef}>
            {data.map((item) => (
              <FeedResultItem feedResultItem={item} size={isBig ? 'big' : 'small'} key={item.id} />
            ))}
            {isLoading ? (
              <>
                <FeedBlockItemSkeleton size={isBig ? 'big' : 'small'} />
                <FeedBlockItemSkeleton size={isBig ? 'big' : 'small'} />
                <FeedBlockItemSkeleton size={isBig ? 'big' : 'small'} />
              </>
            ) : null}

            {isError && !isLoading && <InfoBlock type={type} size={isBig ? 'big' : 'small'} isError />}
          </S.Flickity>
        </>
      ) : type === 'todo' ? (
        <S.FeedBlockTitle>{t('feed.noTodo', { firstName })}</S.FeedBlockTitle>
      ) : null}
    </S.FeedBlock>
  );
};

const S = {
  FeedBlock: styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
  `,
  FeedBlockHeader: styled.div<{ isRtl?: boolean }>`
    display: flex;
    justify-content: space-between;
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    align-items: center;
    margin-bottom: 16px;
  `,
  FeedBlockTitle: styled.span`
    color: ${({ theme }) => theme.colors.black};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: 15px;
    font-weight: 700;
  `,
  SeeAllButton: styled.a`
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  `,
  Flickity: styled(Flickity)`
    &:focus {
      outline: none;
    }
  `,
};

export default memo(FeedBlock);
