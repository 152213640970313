import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { knowledgeHistorySelector, knowledgeIsLoadingSelector } from '../../../store/knowledge/knowledge.selectors';
import React, { memo, useCallback, useEffect } from 'react';
import { fetchHistory } from '../../../store/knowledge/knowledge.slice';
import { AiSuggestionBlockSkeleton } from './Skeleton';
import SuggestionList from '../../Prompt/common/SuggestionList';
import styled from 'styled-components';

const AiSuggestionBlock = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const knowledgeHistory = useSelector(knowledgeHistorySelector);
  const isLoading = useSelector(knowledgeIsLoadingSelector);

  useEffect(() => {
    if (!knowledgeHistory) {
      dispatch(fetchHistory());
    }
  }, [dispatch, knowledgeHistory]);

  const handleSelect = useCallback(() => {
    history.push('/prompt');
  }, [history]);

  return (
    <S.AiSuggestionBlock>
      {isLoading ? (
        <AiSuggestionBlockSkeleton />
      ) : knowledgeHistory?.length ? (
        <>
          <S.AiSuggestionBlockTitle>{t('feed.blockTitles.askAi')}</S.AiSuggestionBlockTitle>
          <SuggestionList data={knowledgeHistory} onSelect={handleSelect} />
        </>
      ) : (
        <S.TryAskAiContainer>
          <S.TryAskAiTitle>{t('feed.tryAskAi')}</S.TryAskAiTitle>
          <S.TryAskAiText>{t('feed.getInstantAnswer')}</S.TryAskAiText>
          <S.TryAskAiButton to='/prompt'>{t('feed.tryItNow')}</S.TryAskAiButton>
        </S.TryAskAiContainer>
      )}
    </S.AiSuggestionBlock>
  );
};

const S = {
  AiSuggestionBlock: styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
  `,
  AiSuggestionBlockTitle: styled.span`
    color: ${({ theme }) => theme.colors.black};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: 15px;
    font-weight: 700;
  `,
  TryAskAiContainer: styled.div`
    display: flex;
    padding: 10px 0px;
    flex-direction: column;
    gap: 4px;
    align-self: stretch;
    border-radius: 9px;
    background: #ebf1fe;
  `,
  TryAskAiTitle: styled.span`
    color: ${({ theme }) => theme.colors.black};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: 15px;
    font-weight: 700;
    text-align: center;
  `,
  TryAskAiText: styled.span`
    color: ${({ theme }) => theme.colors.black};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: 15px;
    font-weight: 400;
    text-align: center;
  `,
  TryAskAiButton: styled(Link)`
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  `,
};

export default memo(AiSuggestionBlock);
