import React, { memo, useCallback } from 'react';
import styled from 'styled-components';
import { TSize } from './FeedResultItem';
import { useDispatch } from 'react-redux';
import { getFeed } from '../../../store/feed/feed.slice';
import { Key } from '../../../store/feed/feed.types';
import { useTranslation } from 'react-i18next';

const SIZE_MAP = {
  coverWidth: {
    big: 219,
    medium: 170,
    small: 130,
  },
  coverHeight: {
    big: 219 / 0.7,
    medium: 170 / 0.7,
    small: 130 / 0.7,
  },
  textWidth: {
    big: 156,
    medium: 121,
    small: 93,
  },
};

interface IProps {
  type: Key;
  size?: TSize;
  fullWidth?: boolean;
  isError?: boolean;
}

const InfoBlock: React.FC<IProps> = ({ type, size = 'small', fullWidth, isError }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleReload = useCallback(() => {
    dispatch(getFeed({ key: type }));
  }, [dispatch, type]);

  return (
    <S.Container isError={isError} fullWidth={fullWidth} size={size}>
      <S.ErrorBlockText fullWidth={fullWidth}>
        {isError ? t('common.somethingWentWrong') : t('common.noData')}
      </S.ErrorBlockText>
      <S.ReloadButton fullWidth={fullWidth} onClick={handleReload}>
        {t('feed.refresh')}
      </S.ReloadButton>
    </S.Container>
  );
};

const S = {
  Container: styled.div<{ size: TSize; fullWidth: boolean; isError?: boolean }>`
    display: flex;
    width: ${({ size, fullWidth }) => (fullWidth ? '100%' : `${SIZE_MAP.coverWidth[size]}px`)};
    height: ${({ size }) => SIZE_MAP.coverHeight[size]}px;
    background-color: ${({ theme, isError }) => (isError ? theme.colors.orange1 : theme.colors.blue2)};

    border-radius: 16px;
    margin: 0 5px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  `,
  ErrorBlockText: styled.div<{ fullWidth: boolean }>`
    display: flex;
    font-size: ${({ fullWidth }) => (fullWidth ? 16 : 13)}px;
    font-weight: 700;
    text-align: center;
    color: ${({ theme }) => theme.colors.white};
  `,
  ReloadButton: styled.div<{ fullWidth: boolean }>`
    display: flex;
    font-size: ${({ fullWidth }) => (fullWidth ? 16 : 13)}px;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-weight: 700;
    text-align: center;
    margin-top: 10px;
    text-decoration: underline;
  `,
};

export default memo(InfoBlock);
