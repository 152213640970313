import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import useComponentWillMount from 'use-component-will-mount';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import QuizIntro from '../../screens/quiz/QuizIntro';
import QuizQuestion from '../../screens/quiz/QuizQuestion';
import QuizEnd from '../../screens/quiz/QuizEnd';
import { fetchQuiz, resetQuiz, selectQuiz } from '../../store/quiz/quiz.slice';
import { Loader } from '../../components/shared';
import MetaTitle from '../../components/MetaTitle';
import MainAppHeader from '../../navigation/MainApp/components/Header';
import gtmTrack from '../../services/googleTagManager/track';
import { authSelector, isAuthenticatedSelector } from '../../store/auth/auth.selectors';
import { useRedirectToHome } from '../../hooks/useRedirectToHome';
import useIsInsideMsTeams from '../../hooks/useIsInsideMsTeams';
import useCustomAuth from '../../hooks/useCustomAuth';
import SideMenu from '../MainApp/components/SideMenu';
import useQueryParams from '../../hooks/useQueryParams';
import { v4 as uuidv4 } from 'uuid';
import { log } from '../../store/tracking/tracking.slice';
import useRemoveQueryParams from '../../hooks/useRemoveQueryParams';
import styled from 'styled-components';
import { ReactComponent as HomeButton } from '../../assets/icons/home.svg';
import { resetFeed } from '../../store/feed/feed.slice';

interface Props {
  match: {
    params: {
      quizId: string;
    };
  };
}

function ViewQuiz(props: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { quizId } = props.match.params;
  const user = useSelector(authSelector);
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const authenticated = useSelector(isAuthenticatedSelector);
  const { isInsideMsTeams } = useIsInsideMsTeams();
  const { skipProfile, isFetchingOrgSettings } = useCustomAuth();
  const { queryParams } = useQueryParams();
  const isPreview = queryParams?.isPreview;
  const selectedQuiz = useSelector(selectQuiz);

  const processId = useMemo(() => uuidv4(), []);

  // this should appear before selecting quiz data to have a clear state
  useComponentWillMount(() => {
    if (Number(selectedQuiz.id) === Number(quizId) && !selectedQuiz.isLoadingQuiz && !selectedQuiz.isError) {
      return;
    }
    dispatch(fetchQuiz({ quizId, processId }));
  });

  const { isLoadingQuiz, subject, side_effects, organization, sharingMode, creator } = useSelector(selectQuiz);
  const isLoadingRef = useRef(isLoadingQuiz);

  const quizUserOrg = useMemo(() => {
    return organization && user?.organizations?.find((org) => parseInt(org.id, 10) === parseInt(organization, 10));
  }, [organization, user?.organizations]);

  useRemoveQueryParams(['timestamp']);
  useRedirectToHome({
    sharingMode,
    userOrg: quizUserOrg,
    processId,
  });

  useEffect(() => {
    if (!isLoadingQuiz && isLoadingRef.current) {
      gtmTrack('pageview', {
        page_title: 'viewQuiz',
        bites_user_id: user?.id,
        quiz_id: quizId,
        content_org_id: organization,
        sharing_mode: sharingMode,
        creator,
      });
    }

    isLoadingRef.current = isLoadingQuiz;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingQuiz]);

  useEffect(() => {
    if (side_effects?.opted_in) {
      toast(t('components.optedInNotification'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [side_effects?.opted_in]);

  useEffect(() => {
    dispatch(resetFeed());
    return () => {
      dispatch(resetQuiz());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDisplayLoading =
    isLoadingQuiz ||
    isFetchingOrgSettings ||
    (!isInsideMsTeams && sharingMode !== 'anyone' && !skipProfile && !quizUserOrg?.is_profile_complete);

  useEffect(() => {
    dispatch(
      log({
        event: 'ViewQuiz: isDisplayLoading changed',
        processId,
        data: {
          isDisplayLoading,
          isLoadingQuiz,
          isFetchingOrgSettings,
          isInsideMsTeams,
          sharingMode,
          skipProfile,
          quizUserOrg,
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisplayLoading]);

  const handleHomeButtonClick = useCallback(() => {
    dispatch(log({ event: 'ViewQuiz.handleHomeButtonClick' }));
    history.push('/feed');
    gtmTrack('back_to_home_button', {});
  }, [dispatch, history]);

  const renderRight = useCallback(() => {
    return (
      <S.HomeButtonContainer onClick={handleHomeButtonClick}>
        <S.HomeButton />
      </S.HomeButtonContainer>
    );
  }, [handleHomeButtonClick]);

  if (isDisplayLoading) {
    return <Loader />;
  }

  return (
    <>
      <ToastContainer position={toast.POSITION.BOTTOM_LEFT} />
      <MetaTitle title={subject} />
      {authenticated && (
        <div>
          <SideMenu isOpen={isSideMenuOpen} onClose={() => setIsSideMenuOpen(false)} />
          <MainAppHeader
            onHamburgerClick={() => setIsSideMenuOpen(true)}
            isPreview={isPreview}
            renderRight={!isPreview && renderRight}
          />
        </div>
      )}
      <Switch>
        <Route path='/quiz/:quizId/intro' component={QuizIntro} />
        <Route path='/quiz/:quizId/question/:questionId' component={QuizQuestion} />
        <Route path='/quiz/:quizId/end' component={QuizEnd} />
        <Redirect to={`/quiz/${quizId}/intro${window.location.search}`} />
      </Switch>
    </>
  );
}

const S = {
  HomeButtonContainer: styled.div`
    width: 41px;
    height: 41px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 7px 24px 0 rgba(0, 0, 0, 0.1);
    margin: 0 16px;
  `,
  HomeButton: styled(HomeButton)`
    width: 24px;
    height: 24px;
  `,
};

export default ViewQuiz;
