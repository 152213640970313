import React, { FC, memo, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { getTotalFeedResultsNumber } from '../../store/feed/feed.slice';
import { FEED_CONFIG } from '../../store/feed/feed.constants';
import FeedBlock from './common/FeedBlock';
import AiSuggestionBlock from './common/AiSuggestionBlock';
import { selectTotalFeedResultsNumber } from '../../store/feed/feed.selectors';
import { organizationHasFeatureFlagSelector } from '../../store/profile/profile.selectors';
import { Features } from '../../utils/featureFlag/featureFlag.types';
import { orgSelector } from '../../store/organization/organization.selectors';

interface IProps {
  orgId?: number;
  isPublicFeed?: boolean;
  withoutProgressIndication?: boolean;
  onBiteClick?: (biteId: number, subject: string) => void;
}

const Feed: FC<IProps> = () => {
  const dispatch = useDispatch();
  const totalFeedResultsNumber = useSelector(selectTotalFeedResultsNumber);
  const { id: orgId } = useSelector(orgSelector);
  const askAiFeatureFlag = useSelector(
    organizationHasFeatureFlagSelector({
      orgId,
      feature: Features.ASK_AI_FEED,
    }),
  );

  useEffect(() => {
    if (totalFeedResultsNumber === null) {
      dispatch(getTotalFeedResultsNumber());
    }
  }, [dispatch, totalFeedResultsNumber]);

  const renderFeedBlock = useCallback(
    (feedConfig) => {
      if (feedConfig.type !== 'ASK_AI') {
        return <FeedBlock key={feedConfig.key + orgId} type={feedConfig.key} isBig={feedConfig.isBig} />;
      }

      if (askAiFeatureFlag) {
        return <AiSuggestionBlock />;
      }

      return null;
    },
    [askAiFeatureFlag, orgId],
  );

  return <S.List>{FEED_CONFIG.map(renderFeedBlock)}</S.List>;
};

const S: any = {
  List: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 100px;
  `,
};

export default memo(Feed);
