import styled from 'styled-components';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchKnowledge } from '../../../store/knowledge/knowledge.slice';
import { knowledgeIsLoadingSelector } from '../../../store/knowledge/knowledge.selectors';
import { getIsRtl as rtl } from '../../../locale/i18n';
import { suggestionItemCy } from '../aiPrompt.constants';
import { THistoryItem } from '../../../store/knowledge/knowledge.types';

interface IProps {
  data: THistoryItem[];
  onSelect?: () => void;
}

const SuggestionList: React.FC<IProps> = ({ data = [], onSelect }) => {
  const isRtl = rtl();
  const dispatch = useDispatch();
  const isPromptLoading = useSelector(knowledgeIsLoadingSelector);

  const handleSuggestionClick = useCallback(
    (event) => {
      if (isPromptLoading) {
        return;
      }

      if (onSelect) {
        onSelect();
      }

      const text = event.target.innerText;
      dispatch(fetchKnowledge(text));
    },
    [dispatch, isPromptLoading, onSelect],
  );

  if (!data?.length) {
    return null;
  }

  return (
    <S.SuggestionListContainer>
      <S.SuggestionList isRtl={isRtl}>
        {data.map(({ prompt, id }) => (
          <S.Suggestion data-cy={suggestionItemCy} onClick={handleSuggestionClick} key={id} isRtl={isRtl}>
            <S.SuggestionText isRtl={isRtl}>{prompt}</S.SuggestionText>
          </S.Suggestion>
        ))}
      </S.SuggestionList>
      <S.WhiteShadow isRtl={isRtl} />
    </S.SuggestionListContainer>
  );
};

const S = {
  SuggestionListContainer: styled.div`
    position: relative;
    height: 52px;
  `,
  SuggestionList: styled.div<{ isRtl: boolean }>`
    display: flex;
    position: relative;
    height: 52px;
    margin: 12px 0;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: scroll;
    scrollbar-width: none;
    ${({ isRtl }) => (isRtl ? 'padding-left' : 'padding-right')}: 30px;
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
  `,
  Suggestion: styled.div<{ isRtl: boolean }>`
    cursor: pointer;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 52px;
    min-width: 100px;
    max-width: 150px;
    border: 1px solid ${({ theme }) => theme.colors.gray19};
    border-radius: 7px;
    padding: 10px;
    ${({ isRtl }) => (isRtl ? 'margin-right' : 'margin-left')}: 7px;
  `,
  SuggestionText: styled.span<{ isRtl: boolean }>`
    white-space: normal;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.text};
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  `,
  WhiteShadow: styled.div<{ isRtl: boolean }>`
    position: absolute;
    top: 0;
    bottom: 0;
    width: 52px;
    height: 52px;
    background: linear-gradient(
      ${({ isRtl }) => (isRtl ? 'to left' : 'to right')},
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );
    ${({ isRtl }) => (isRtl ? 'left' : 'right')}: 0;
  `,
};

export default SuggestionList;
