import { useHistory, useParams } from 'react-router-dom';
import { FEED_BLOCK_CONFIGS } from '../../store/feed/feed.constants';
import React, { useCallback, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import BackButton from '../../components/shared/Buttons/BackButton';
import { HEADER_HEIGHT } from '../../components/layout/StyledHeader';
import { getIsRtl } from '../../locale/i18n';
import FeedResultItem from './common/FeedResultItem';
import { useDispatch, useSelector } from 'react-redux';
import { keyDataSelector } from '../../store/feed/feed.selectors';
import { useDTListCombinedData } from '../../hooks/useDTListCombinedData';
import { useDTListCombinedHasMore } from '../../hooks/useDTListCombinedHasMore';
import { getFeed } from '../../store/feed/feed.slice';
import { Key } from '../../store/feed/feed.types';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FeedBlockItemSkeleton } from './common/Skeleton';
import { useDTListCombinedError } from '../../hooks/useDTListCombinedError';
import InfoBlock from './common/InfoBlock';
import AskMeAnythingBottomSheet from '../Home/components/AskMeAnythingBottomSheet';

const FocusedFeedBlock = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { type } = useParams<{ type: Key }>();
  const history = useHistory();
  const feedBlockData = useSelector(keyDataSelector(type));
  const data = useDTListCombinedData(feedBlockData);
  const hasMore = useDTListCombinedHasMore(feedBlockData);
  const isInitialLoading = feedBlockData[0].isLoading && feedBlockData[0].data === null;
  const isError = useDTListCombinedError(feedBlockData);

  useEffect(() => {
    if (!data.length && !isInitialLoading && !isError) {
      dispatch(getFeed({ key: type }));
    }
  }, [data.length, dispatch, isError, isInitialLoading, type]);

  const handleNext = useCallback(() => {
    dispatch(getFeed({ key: type }));
  }, [dispatch, type]);

  const handleGoBack = useCallback(() => {
    history.push('/');
  }, [history]);

  return (
    <S.Container>
      <S.Header>
        <BackButton onClick={handleGoBack} />
        <S.FeedBlockTitle>{t(FEED_BLOCK_CONFIGS[type].title)}</S.FeedBlockTitle>
        <S.EmptyDiv />
      </S.Header>
      <S.FeedResultList
        dataLength={data.length}
        next={handleNext}
        hasMore={hasMore && !isError}
        scrollableTarget='app-container'
        loader={
          <>
            <FeedBlockItemSkeleton size={'medium'} />
            <FeedBlockItemSkeleton size={'medium'} />
            <FeedBlockItemSkeleton size={'medium'} />
            <FeedBlockItemSkeleton size={'medium'} />
          </>
        }
      >
        {data.map((item) => (
          <FeedResultItem feedResultItem={item} size={'medium'} key={item.id} />
        ))}
        {!data.length && !isError && (
          <S.InfoBlock>
            <InfoBlock type={type} size={'medium'} fullWidth />
          </S.InfoBlock>
        )}
        {isError && (
          <S.InfoBlock>
            <InfoBlock type={type} size={'medium'} fullWidth isError />
          </S.InfoBlock>
        )}
      </S.FeedResultList>

      <AskMeAnythingBottomSheet />
    </S.Container>
  );
};

const S = {
  Container: styled.div`
    overflow: visible;
    flex: 1;
  `,
  Header: styled.div`
    overflow: visible;
    width: 100%;
    height: ${HEADER_HEIGHT}px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 49px;
    ${({ theme }) => css`
      color: ${theme.colors.text};
    `};
    ${() =>
      getIsRtl() &&
      `
			direction: rtl;
		`};
  `,
  FeedBlockTitle: styled.div`
    color: ${({ theme }) => theme.colors.black};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: 15px;
    font-weight: 700;
  `,
  FeedResultList: styled(InfiniteScroll)`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-row-gap: 23px;
    padding: 0 20px 20px;
    ${() => (getIsRtl() ? 'direction: rtl;' : '')};
    writing-mode: horizontal-tb;
    justify-items: center;
    align-items: flex-start;
  `,
  EmptyDiv: styled.div`
    width: 41px;
    height: 41px;
  `,
  InfoBlock: styled.div`
    grid-column: span 2;
    width: 100%;
    display: flex;
    padding: 20px;
  `,
};

export default FocusedFeedBlock;
